import Vue from 'vue';
import VueRouter from 'vue-router';
import { getStore,setStore,removeStore } from '@/utils/storage';
import { getCurpermissions } from '@/utils/common';

// const originalPush = VueRouter.prototype.push

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)

const Layout = () => import(/* webpackeChunkName: "layout" */ '@/components/Layout.vue');


export const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  },
  {
    path:'*',
    redirect:'/login'
  }
]

export const allRoutes=[
  {
    path: '/cms/bannerlist',
    name: 'bannerList',
    component: () => import(/* webpackChunkName: "bannerList" */ '@/views/bannerList/index.vue'),
    meta: {
      parentIndex: "1",
      parentIcon:"el-icon-s-tools",
      parentTitle:"App 配置管理",
      title: "Banner管理",
      index:"1-1",
      type:"show",
      code:"HilaiFoods.Banner",
      keyCode: 102
    }
  },
  {
    path: '/cms/banneredit',
    name: 'bannerEdit',
    component: () => import(/* webpackChunkName: "bannerEdit" */ '@/views/bannerList/bannerEdit.vue'),
    meta: {
      parentIndex: "1",
      title: "banner編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/bannerlist',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.Banner"
    }
  },
  {
    path: '/cms/pushlist',
    name: 'pushList',
    component: () => import(/* webpackChunkName: "pushList" */ '@/views/pushList/index.vue'),
    meta: {
      parentIndex: "1",
      parentIcon:"el-icon-s-tools",
      parentTitle:"App 配置管理",
      title: "通知訊息",
      index:"1-2",
      type:"show",
      code:"HilaiFoods.Push",
      keyCode: 102
    }
  },
  {
    path: '/cms/pushedit',
    name: 'pushEdit',
    component: () => import(/* webpackChunkName: "pushEdit" */ '@/views/pushList/pushEdit.vue'),
    meta: {
      parentIndex: "1",
      title: "通知訊息編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/pushlist',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.Push"
    }
  },
  {
    path: '/cms/newestinfo',
    name: 'newestInfo',
    component: () => import(/* webpackChunkName: "newestInfo" */ '@/views/newestInfo/index.vue'),
    meta: {
      parentIndex: "1",
      parentTitle:"App 配置管理",
      title: "最新消息",
      index:"1-4",
      type:"show",
      code:"HilaiFoods.News",
      keyCode: 102
    }
  },
  {
    path: '/cms/coverad',
    name: 'coverAd',
    component: () => import(/* webpackChunkName: "coverAd" */ '@/views/coverAd/index.vue'),
    meta: {
      parentIndex: "1",
      parentTitle:"App 配置管理",
      title: "蓋板廣告",
      index:"1-5",
      type:"show",
      code:"HilaiFoods.CoverBanner",
      keyCode: 102
    }
  },
  {
    path: '/cms/coveradedit',
    name: 'coveradEdit',
    component: () => import(/* webpackChunkName: "coveradEdit" */ '@/views/coverAd/coveradEdit.vue'),
    meta: {
      parentIndex: "1",
      title: "蓋板廣告編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/coverad',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.CoverBanner"
    }
  },
  {
    path: '/cms/version',
    name: 'version',
    component: () => import(/* webpackChunkName: "version" */ '@/views/version/index.vue'),
    meta: {
      parentIndex: "1",
      parentTitle:"App 配置管理",
      title: "版本管理",
      index:"1-6",
      type:"show",
      code:"HilaiFoods.AppVersion",
      keyCode: 102
    }
  },
  {
    path: '/cms/versionedit',
    name: 'versionEdit',
    component: () => import(/* webpackChunkName: "version" */ '@/views/version/versionEdit.vue'),
    meta: {
      parentIndex: "1",
      parentTitle:"App 配置管理",
      title: "版本管理編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/version',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.AppVersion",
    }
  },
  // {
  //   path: '/cms/privacyEdit',
  //   name: 'privacyEdit',
  //   component: () => import(/* webpackChunkName: "privacyEdit" */ '@/views/privacyInfo/privacyEdit.vue'),
  //   meta: {
  //     parentIndex: "1",
  //     title: "隱私聲明編輯",
  //     type:"hide",
  //     activeTargetState: true,//是否启用高亮指向功能
  //     activeTargetPath: '/cms/privacyinfo'//选中当前菜单时需要高亮的路由path
  //   }
  // },
  {
    path: '/cms/VIPCardList',
    name: 'VIPCardList',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/VIPCard/index.vue'),
    meta: {
      parentIcon:"el-icon-user-solid",
      type:"show",
      parentIndex: "2",
      title: "貴賓卡等級設定",
      index:"2-4",
      parentTitle:"會員中心",
      code:"HilaiFoods.MemberLevelSetting"
    }
  },
  {
    path: '/cms/VIPCardEdit',
    name: 'VIPCardEdit',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/VIPCard/edit.vue'),
    meta: {
      type:"hide",
      parentIndex: "2",
      title: "貴賓卡等級設定明細",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/VIPCardList',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.MemberLevelSetting"
    }
  },
  {
    path: '/cms/userlist',
    name: 'userList',
    component: () => import(/* webpackChunkName: "userList" */ '@/views/userList/index.vue'),
    meta: {
      parentIndex: "2",
      parentIcon:"el-icon-user-solid",
      parentTitle:"會員中心",
      title: "會員帳號",
      index:"2-1",
      type:"show",
      code:"HilaiFoods.Member",
      keyCode: 102
    }
  },
  {
    path: '/cms/consumptionlog',
    name: 'consumptionLog',
    component: () => import(/* webpackChunkName: "consumptionLog" */ '@/views/consumption/index.vue'),
    meta: {
      parentIndex: "2",
      parentIcon:"el-icon-user-solid",
      parentTitle:"會員中心",
      title: "會員消費記錄",
      index:"2-2",
      type:"show",
      code:"HilaiFoods.ConsumeRecord",
      keyCode: 102
    }
  },
  {
    path: '/cms/userconsumptionlog',
    name: 'userConsumptionLog',
    component: () => import(/* webpackChunkName: "userConsumptionLog" */ '@/views/consumption/usercsLog.vue'),
    meta: {
      parentIndex: "2",
      title: "會員消費記錄明細",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/consumptionlog',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.ConsumeRecord"
    }
  },
  {
    path: '/cms/contactus',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "contactUs" */ '@/views/contactUs/index.vue'),
    meta: {
      parentIndex: "2",
      parentIcon:"el-icon-user-solid",
      parentTitle:"會員中心",
      title: "聯絡我們",
      index:"2-3",
      type:"show",
      code:"HilaiFoods.ContactUs",
      keyCode: 102
    }
  },
  {
    path: '/cms/infoedit',
    name: 'infoEdit',
    component: () => import(/* webpackChunkName: "infoEdit" */ '@/views/contactUs/infoEdit.vue'),
    meta: {
      parentIndex: "2",
      title: "信息編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/contactus',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.ContactUs"
    }
  },
  {
    path: '/cms/restaurantlist',
    name: 'restaurantList',
    component: () => import(/* webpackChunkName: "restaurantList" */ '@/views/restaurantList/index.vue'),
    meta: {
      parentIndex: "3",
      parentIcon:"el-icon-food",
      parentTitle:"餐廳管理",
      title: "餐廳資訊",
      index:"3-1",
      type:"show",
      code:"HilaiFoods.Counter",
      keyCode: 102
    }
  },
  {
    path: '/cms/restaurantdetail',
    name: 'restaurantDetail',
    component: () => import(/* webpackChunkName: "bannerEdit" */ '@/views/restaurantList/restaurantDetail.vue'),
    meta: {
      parentIndex: "3",
      title: "餐廳明細",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/restaurantlist',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.Counter"
    }
  },
  {
    path: '/cms/couponlist',
    name: 'couponlist',
    component: () => import(/* webpackChunkName: "couponList" */ '@/views/couponList/index.vue'),
    meta: {
      parentIndex: "4",
      parentIcon:"el-icon-money",
      parentTitle:"優惠券管理",
      title: "優惠券管理",
      index:"4-1",
      type:"show",
      code:"HilaiFoods.Coupon",
      keyCode: 102
    }
  },
  {
    path: '/cms/memberWallet',
    name: 'memberWallet',
    component: () => import(/* webpackChunkName: "couponList" */ '@/views/memberWallet/index.vue'),
    meta: {
      parentIndex: "4",
      parentIcon:"el-icon-money",
      parentTitle:"會員票夾管理",
      title: "會員票夾管理",
      index:"4-2",
      type:"show",
      code:"HilaiFoods.CouponWallet",
      keyCode: 102
    }
  },
  {
    path: '/cms/memberWalletLog',
    name: 'memberWalletLog',
    component: () => import(/* webpackChunkName: "userConsumptionLog" */ '@/views/memberWallet/memberWalletLog.vue'),
    meta: {
      parentIndex: "4",
      title: "會員票夾消費記錄明細",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/memberWallet',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.CouponWallet"
    }
  },
  {
    path: '/cms/couponedit',
    name: 'couponEdit',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/couponList/couponEdit.vue'),
    meta: {
      parentIndex: "4",
      title: "優惠券編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/couponlist',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.Coupon"
    }
  },
  {
    path: '/cms/details',
    name: 'details',
    component: () => import(/* webpackChunkName: "details" */ '@/views/couponList/details.vue'),
    meta: {
      parentIndex: "4",
      title: "優惠券明細",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/couponlist',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.Coupon"
    }
  },
  {
    path: '/cms/recommendationcode',
    name: 'recommendationCode',
    component: () => import(/* webpackChunkName: "recommendationCode" */ '@/views/recommendationCode/index.vue'),
    meta: {
      parentIndex: "5",
      parentIcon:"el-icon-postcard",
      parentTitle:"推薦碼",
      title: "推薦碼",
      index:"5-1",
      type:"show",
      code:"HilaiFoods.RecommendRecord",
      keyCode: 102
    }
  },
  {
    path: '/cms/ipaccess',
    name: 'ipAccess',
    component: () => import(/* webpackChunkName: "ipAccess" */ '@/views/ipAccess/index.vue'),
    meta: {
      parentIndex: "6",
      parentIcon:"el-icon-notebook-2",
      parentTitle:"白名單管理",
      title: "白名單",
      index:"6-1",
      type:"show",
      code:"HilaiFoods.IPAccess",
      keyCode: 102
    }
  },
  {
    path: '/cms/usermanagement',
    name: 'userManagement',
    component: () => import(/* webpackChunkName: "userManagement" */ '@/views/userManagement/index.vue'),
    meta: {
      parentIndex: "7",
      parentIcon:"el-icon-tickets",
      parentTitle:"用戶管理",
      title: "用戶",
      index:"7-1",
      type:"show",
      code:"HilaiFoods.User",
      keyCode: 102
    }
  },
  {
    path: '/cms/usermanagementedit',
    name: 'userManagementedit',
    component: () => import(/* webpackChunkName: "userManagementedit" */ '@/views/userManagement/userManagementedit.vue'),
    meta: {
      parentIndex: "7",
      title: "用戶編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/usermanagement',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.User"
    }
  },
  {
    path: '/cms/authoritymanagement',
    name: 'authorityManagement',
    component: () => import(/* webpackChunkName: "authorityManagement" */ '@/views/authorityManagement/index.vue'),
    meta: {
      parentIndex: "8",
      parentIcon:"el-icon-setting",
      parentTitle:"角色管理",
      title: "角色",
      index:"8-1",
      type:"show",
      code:"HilaiFoods.Role",
      keyCode: 102
    }
  },
  {
    path: '/cms/authoritymanagementedit',
    name: 'authorityManagementedit',
    component: () => import(/* webpackChunkName: "authorityManagementedit" */ '@/views/authorityManagement/authorityManagementedit.vue'),
    meta: {
      parentIndex: "8",
      title: "角色编辑",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/authoritymanagement',
      code:"HilaiFoods.Role"//选中当前菜单时需要高亮的路由path
    }
  },
  {
    path: '/cms/reportForm',
    name: 'reportForm',
    component: () => import(/* webpackChunkName: "reportForm" */ '@/views/reportForm/index.vue'),
    meta: {
      parentIndex: "9",
      parentIcon:"el-icon-document",
      parentTitle:"報表分析",
      title: "貢獻度分析",
      index:"9-1",
      type:"show",
      code:"HilaiFoods.ContributionAnalysis",
      keyCode: 102
    }
  },
  {
    path: '/cms/questionnaireManagement',
    name: 'questionnaireManagement',
    component: () => import(/* webpackChunkName: "reportForm" */ '@/views/questionnaireManagement/index.vue'),
    meta: {
      parentIndex: "10",
      parentIcon:"el-icon-document-copy",
      parentTitle:"問卷調查管理",
      title: "問卷調查管理",
      index:"10-1",
      type:"show",
      code:"HilaiFoods.Questionnaire",
      keyCode: 102
    }
  },
  {
    path: '/cms/questionnaireManagementEdit',
    name: 'questionnaireManagementEdit',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/questionnaireManagement/questionnaireManagementEdit.vue'),
    meta: {
      parentIndex: "10",
      title: "優惠券編輯",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/questionnaireManagement',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.Questionnaire"
    }
  },
  {
    path: '/cms/cardInterest',
    name: 'cardInterest',
    component: () => import(/* webpackChunkName: "reportForm" */ '@/views/privacyManagement/cardInterest.vue'),
    meta: {
      parentIndex: "11",
      parentIcon:"el-icon-document-copy",
      parentTitle:"隱私管理",
      title: "卡友權益",
      index:"11-1",
      type:"show",
      code:"HilaiFoods.CardFriendBenefits",
      keyCode: 102
    }
  },
  {
    path: '/cms/memberInterest',
    name: 'memberInterest',
    component: () => import(/* webpackChunkName: "reportForm" */ '@/views/privacyManagement/memberInterest.vue'),
    meta: {
      parentIndex: "11",
      parentIcon:"el-icon-document-copy",
      parentTitle:"會員權益管理",
      title: "會員權益管理",
      index:"11-2",
      type:"show",
      code:"HilaiFoods.MemberBenefits",
      keyCode: 102
    }
  },
  {
    path: '/cms/privacyedit',
    name: 'privacyEdit',
    component: () => import(/* webpackChunkName: "privacyEdit" */ '@/views/privacyInfo/privacyEdit.vue'),
    meta: {
      parentIndex: "11",
      parentTitle:"App 配置管理",
      title: "隱私聲明編輯",
      index:"11-3",
      type:"show",
      code:"HilaiFoods.PrivacyPolicy",
      keyCode: 102
    }
  },
  {
    path: '/cms/brandList',
    name: 'brandList',
    component: () => import(/* webpackChunkName: "brandList" */ '@/views/brandList/index.vue'),
    meta: {
      parentIndex: "4",
      parentIcon:"el-icon-s-tools",
      parentTitle:"優惠券管理",
      title: "品牌核銷查詢",
      index:"4-1",
      type:"show",
      code:"HilaiFoods.BrandWriteoff",
      keyCode: 102
    }
  },
  {
    path: '/cms/brandDetails',
    name: 'brandDetails',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/brandList/details.vue'),
    meta: {
      parentIndex: "10",
      title: "品牌核銷詳情",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/brandDetails',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.BrandWriteoff"
    }
  },
  {
    path: '/cms/gameList',
    name: 'gameList',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/gameSettings/gameList.vue'),
    meta: {
      parentIcon:"el-icon-setting",
      type:"show",
      parentIndex: "12",
      title: "滿額送遊戲設定",
      index:"12-1",
      parentTitle:"遊戲管理",
      code:"HilaiFoods.GameActivity"
    }
  },
  {
    path: '/cms/gameEdit',
    name: 'gameEdit',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/gameSettings/gameEdit.vue'),
    meta: {
      parentIndex: "10",
      title: "遊戲設定",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/gameEdit',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.BrandWriteoff"
    }
  },
  {
    path: '/cms/gameDetail',
    name: 'gameDetail',
    component: () => import(/* webpackChunkName: "couponEdit" */ '@/views/gameSettings/gameDetail.vue'),
    meta: {
      parentIndex: "10",
      title: "遊戲詳情",
      type:"hide",
      activeTargetState: true,//是否启用高亮指向功能
      activeTargetPath: '/cms/gameDetail',//选中当前菜单时需要高亮的路由path
      code:"HilaiFoods.BrandWriteoff"
    }
  },
]
//创建路由
const createRouter = () => new VueRouter({
  mode: 'hash',
  routes: [...routes]
})

const router = createRouter();

// reset router
export function resetRouter(role="") {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
  if(role==="admin"){
    let permissionsarr = JSON.parse(getStore("permissions"));
    let newroutes = [{
      path: '/cms',
      component: Layout,
      children:[]
    }]
    allRoutes.forEach(item=> {
      for(let i = 0;i<permissionsarr.length;i++) {
        if(item.meta && item.meta.code == permissionsarr[i].code &&permissionsarr[i].permissionType != 0) {
            newroutes[0].children.push(item)
        }
      }
    })
    let firstPath= newroutes[0].children.length>0 ? newroutes[0].children[0].path : "/login";
    newroutes[0].children.push({path: '',redirect: firstPath})
    router.addRoute(...newroutes)
  }
}
// 是否刷新页面
let refresh = true;
router.beforeEach((to, from, next) => {
  if(refresh && getStore("token") && to.redirectedFrom && to.redirectedFrom!=="/" && to.redirectedFrom!=="/cms/login"){
    getCurpermissions().then(res=>{
      if(res.systemCode===200){
        setStore("permissions",res.data);
        resetRouter("admin");
        refresh = false;
        next({path: to.redirectedFrom});
      }
    })
    // resetRouter("admin");
    // refresh = false;
    // next({path: to.redirectedFrom});
  }else{
    // 登录页
    if (to.path === "/login") {
      // 進入登錄頁需要將refresh設置為false,否則點擊登錄會進入死循環
      refresh=false;
      next();
    } else {
      // 非登录页
      if (getStore("token")) {
        next();
      } else {
        next({ path: '/login' });
      }
    }
  }
});

export default router

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {getStore,setStore} from "@/utils/storage.js";
export default {
  data(){
    return{
      titleArr:["漢來美食Dev測試版CMS","漢來美食QA測試版CMS","漢來美食UAT測試版CMS","漢來美食CMS"]
    }
  },
  created(){
    this.initLanguage();
    this.setTitle();
  },
  methods:{
      initLanguage() {
        if (getStore('DefaultLanguage') == false || getStore('DefaultLanguage') == null) {
          this.$i18n.locale = "tw";
        }else{
          this.$i18n.locale = getStore('DefaultLanguage');
        }
      },
      setTitle(){
        switch(location.hostname){
            case "localhost":
                setStore("pageTitle",this.titleArr[0]);
                document.title = this.titleArr[0];
                break;
            case "azdockerq.mtel.ws":
                setStore("pageTitle",this.titleArr[1]);
                document.title = this.titleArr[1];
                break;
            case "appuatcms.hilaifoods.com":
                setStore("pageTitle",this.titleArr[2]);
                document.title = this.titleArr[2];
                break;
            default:
                setStore("pageTitle",this.titleArr[3])
                document.title = this.titleArr[3];
        }
      }
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

</style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';

// 导入语言包信息
import en from "./locale/en.json";
import tw from "./locale/zh-TW.json";
// 注册i18n
Vue.use(VueI18n);

// 语言包根据语言环境分类
const messages = {
    "en":en,
    "tw":tw
}

// 通过选项创建 VueI18n 实例并导出
export default new VueI18n({
    locale: 'tw', // 设置当前默认语言环境
    messages, // 设置语言环境对应信息
})
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale'
import en from 'element-ui/lib/locale/lang/en'
import tw from 'element-ui/lib/locale/lang/zh-TW'
import i18n from './i18n';// 引入i18n实例
import * as filters from './utils/filters.js';

import "./assets/css/reset.css"
import './assets/css/normalize.css'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.min.css'

Vue.use(ElementUI,{ size: 'small'})

// 设置语言
function InitLanguage() {
  // console.log(localStorage.getItem('DefaultLanguage'))
  if (localStorage.getItem('DefaultLanguage') == false || localStorage.getItem('DefaultLanguage') == null) {
    localStorage.setItem('DefaultLanguage', 'tw')
  }
  return localStorage.getItem('DefaultLanguage');
}

if(InitLanguage()!==''){
  locale.use(tw)
}else{
  locale.use(en)
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

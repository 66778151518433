var HOST ="";
console.log(location.hostname)
switch(location.hostname){
    case "localhost":
        HOST="/devApi";
        break;
    case "azdockerq.mtel.ws":
        HOST="http://azdockerq.mtel.ws:5215";
        break;
    case "appuatcms.hilaifoods.com":
        HOST="https://appuat.hilaifoods.com";
        break;
    case "30.252.8.16":
        HOST="http://azdockerq.mtel.ws:5215";
        break;
    case "172.16.102.69":
        HOST="http://azdockerq.mtel.ws:5215";
        break;
    default:
        // HOST=location.protocol + '//'+location.hostname;
        HOST="https://app.hilaifoods.com";
}
export const API = {
    'login': HOST + '/api/cms/admin/login', // 用户登录
    'memberList': HOST + '/api/cms/member', // 會員列表
    'memberEdit': HOST + '/api/cms/member/edit', // 會員列表
    'memberExportReport': HOST + '/api/cms/member/export-report', // 導出推薦碼
    'counterList': HOST + '/api/cms/counter', // 餐廳列表
    'brandList': HOST + '/api/app/counter/brand',//所有品牌
    'counterDetail': HOST + '/api/cms/counter',//餐廳明細
    'counterEdit': HOST + '/api/cms/counter/edit',//餐廳編輯
    'bannerList': HOST + '/api/cms/banner', // 獲取Banner列表
    'bannerEdit': HOST + '/api/cms/banner/edit', // 添加或編輯Banner
    'uploadImage': HOST + '/api/cms/common/upload-image', // 上傳圖片
    'uploadFile': HOST + '/api/cms/common/upload-file', // 上傳文件
    'bannerSelectData': HOST + '/api/cms/banner/select-data', // 获取最新消息/餐厅下拉选择框
    'counterBrandSelect': HOST + '/api/cms/counter/brand-select',//獲取品牌下拉框
    'websiteRestaurant': HOST + '/api/cms/website/sync-restaurant',//同步官網餐廳資訊
    'pushList': HOST + '/api/cms/push', // 通知信息列表
    'pushEdit': HOST + '/api/cms/push/edit', // 添加或編輯通知信息
    'uploadLimitMember': HOST + '/api/cms/push/limit-member', // 上傳會員列表
    'contactUsList': HOST + '/api/cms/contact-us',//獲取聯繫我們列表
    'contactUsEdit': HOST + '/api/cms/contact-us/edit',//編輯聯繫我們
    'privacyPolicy': HOST + '/api/cms/privacy-policy',//隱私保護聲明詳情
    'privacyPolicyEdit': HOST + '/api/cms/privacy-policy/edit',//編輯隱私政策
    'recommendList': HOST + '/api/cms/member/recommend-record-list',//獲取會員推薦記錄
    'couponList': HOST + '/api/cms/coupon', //優惠券列表
    'getDetails' : HOST + '/api/cms/coupon/details-list', //獲取優惠卷明細
    'couponEdit': HOST + '/api/cms/coupon/edit', //編輯優惠券
    'versionList': HOST + '/api/cms/version', //版本列表
    'versionEdit': HOST + '/api/cms/version/edit', //版本編輯
    'limitMemberTemplate': HOST + "/LimitMemberTemplate.xlsx",//模板下載
    'CouponMemberTemplate': HOST + "/CouponMember.xlsx",//模板下載
    'ipAccess': HOST + "/api/cms/ip-access",//全部IP
    'ipAccessEdit': HOST + "/api/cms/ip-access/edit",//全部IP
    'getUser': HOST + "/api/cms/admin",//獲取User列表
    'memberBirthdayUpdateRecord': HOST + "/api/cms/member/member-birthday-update-record",//
    'getAllrole': HOST + "/api/cms/role/role",//獲取所有角色
    'operationRole': HOST + "/api/cms/role",//操作角色
    'userEdit': HOST + "/api/cms/admin/edit",//添加或編輯用戶
    'getRolelist': HOST + "/api/cms/role/role-list",//獲取角色列表
    'getAllpermissions': HOST + "/api/cms/role/permissions",//獲取所有權限
    'getCurpermissions': HOST + "/api/cms/role/role-permissions",//獲取當前角色權限
    'roleEdit': HOST + "/api/cms/role/edit",//添加或者編輯角色
    'paymentMemberList': HOST + "/api/cms/payment/payment-member-list",//查詢消費會員
    'paymentRecordList': HOST + "/api/cms/payment/payment-record-list",//查詢消費明細
    'regionList': HOST + "/api/cms/payment/region-list",//查詢區域
    'shopList': HOST + "/api/cms/payment/counter-list",//查詢店別
    'brandBieList': HOST + "/api/cms/payment/brand-list",//查詢品牌
    'paymentExportReport': HOST + "/api/cms/payment/export-report",//導出
    'newsList': HOST + "/api/cms/news/news-list",//最新消息
    'selectNews': HOST + "/api/cms/news",//最新消息
    'coverBanner': HOST + "/api/cms/cover-banner",//獲取蓋板廣告列表
    'coverBannerEdit': HOST + "/api/cms/cover-banner/edit",//添加或編輯Banner
    'memberBonusList': HOST + "/api/cms/member/member-bonus-list",//点数记录
    'memberBonusExportReport': HOST + "/api/cms/member/export-member-bonus-report",//會員導出
    'memberBExportReport': HOST + "/api/cms/member/export-member-report",//會員列表導出
    'memberStatistics': HOST + "/api/cms/member/member-statistics",//會員統計
    'refreshActivity': HOST + "/api/cms/coupon/refresh-activity-detailed-statistics",//會員統計
    'typeSelectList': HOST + "/api/cms/coupon/type-select-list",//抵用券类型
    'exportRecordReport': HOST + "/api/cms/coupon/export-record-report",//匯出優惠券明細
    'contributionAnalysis': HOST + "/api/cms/table/contribution-analysis-table",//貢獻度分析
    'tableBrandList': HOST + "/api/cms/table/brand-list",//貢獻度分析
    'exportPaymentMemberReport': HOST + "/api/cms/payment/export-payment-member-report",//導出消費會員
    'couponMessageList': HOST + "/api/cms/push/coupon-message-list",//獲取優惠券列表
    'exportDetailsReport': HOST + "/api/cms/table/export-details-report",//匯出貢獻度分析
    'questionnaireList': HOST + "/api/cms/questionnaire",//獲取questionnaire列表
    'questionnaireCoupon': HOST + "/api/cms/questionnaire/questionnaire-coupon",//獲取問卷綁定優惠券
    'questionnaireEdit': HOST + "/api/cms/questionnaire/edit",//添加或編輯問卷
    'questionnaireDetail': HOST + "/api/cms/questionnaire",//添加或編輯問卷


    'cardFriendBenefits': HOST + "/api/cms/card-friend-benefits",//獲取卡友權益詳情
    'cardFriendBenefitsEdit': HOST + "/api/cms/card-friend-benefits/edit",//編輯卡友權益
    'memberBenefits': HOST + "/api/cms/member-benefits",//獲取會員權益詳情
    'memberBenefitsEdit': HOST + "/api/cms/member-benefits/edit",//編輯會員權益
    'couponWallet': HOST + "/api/cms/coupon-wallet",//會員票夾
    'couponWalletPaymentRecord': HOST + "/api/cms/coupon-wallet/payment-record-list",//會員票夾

    'brandWriteOffList': HOST + "/api/cms/coupon/brand-writeoff-list",//品牌核銷查詢
    'brandWriteOffDetailList': HOST + "/api/cms/coupon/brand-writeoff-details-output",//品牌核销查询明细
    'exportBrandWriteOffList': HOST + "/api/cms/coupon/export-brand-writeoff-report",//導出品牌核銷查詢列表
    'exportBrandWriteOffDetailList': HOST + "/api/cms/coupon/export-brand-writeoff-details-report",//導出品牌核銷明细列表

    'gameActivity': HOST + "/api/cms/game-activity",//遊戲設定
    'gameEditOrAdd': HOST + "/api/cms/game-activity/edit",//遊戲設定添加或編輯
    'gameCoupon': HOST + "/api/cms/game-activity/game-activity-coupon",//獲取遊戲綁定優惠券
    'gameRecord': HOST + "/api/cms/game-activity/game-reward-record",//獲取獲獎記錄
    'gameReissue': HOST + "/api/cms/game-activity/reissue-game-reward",//遊戲獎項補發

    'memberLevelSet': HOST + "/api/cms/member-level-setting",//等級設置
    'memberLevelSetCoupon': HOST + "/api/cms/member-level-setting/member-level-setting-coupon",//獲取等級設定綁定優惠券
    'memberLevelSetUpgrading': HOST + "/api/cms/member-level-setting/upgrading-standards",//升等規範
    'memberLevelTypeList': HOST + '/api/cms/member-level-setting/type-select-list',//獲取貴賓卡等級類型下拉框

}
